<script setup>
</script>

<template>
	<div class="container">
		<section class="about">
			<div class="container-fluid p-0">
				<div class="row g-0">
					<div class="col-lg-8 my-auto showcase-text p-4">
						<h1>Privacy Policy</h1>
						<h5>Effective Date: October 29, 2024</h5>
						<p class="lead">
							At Lucentary Academy Inc (hereinafter referred to as "we," "us," or "our"), we are committed to protecting your privacy.
							This Privacy Policy outlines how we collect, use, and safeguard your information when you visit our website, https://lucentary.com, and engage with our services.
						</p>

						<h3>Information We Collect</h3>
						<p class="lead">
							We may collect the following types of information:
							<ul>
								<li><strong>Personal Information: </strong>This includes your name, email address, phone number, and any other information you provide when you register for our services or contact us.</li>
								<li><strong>Usage Data: </strong>We collect information about your interactions with our website, including your IP address, browser type, pages visited, and the time and date of your visit.</li>
								<li><strong>Cookies and Tracking Technologies: </strong>We use cookies and similar technologies to enhance your experience on our website.  You can manage your cookie preferences through your browser settings.</li>
							</ul>
						</p>

						<h3>How We Use Your Information</h3>
						<p class="lead">
							We use the information we collect for various purposes, including:
							<ul>
								<li>To provide, maintain, and improve our services</li>
								<li>To communicate with you, including sending updates, newsletters, and promotional materials</li>
								<li>To respond to your inquiries and provide customer support</li>
								<li>To monitor usage and analyze trends to enhance user experience</li>
							</ul>
						</p>

						<h3>Text Messaging Policy</h3>
						<p class="lead">
							By providing your phone number, you consent to receive text messages from us regarding your account, updates, and promotional offers.
						</p>
						<p class="lead">
							<strong>Opt-In: </strong> You will receive a confirmation message after opting in to our text messaging program.
						</p>
						<p class="lead">
							<strong>Opt-Out: </strong>If you wish to stop receiving text messages, you can opt-out at any time by replying "STOP" to any text message you receive from us.
							You may also contact us directly at info@lucentary.com to request removal from our text messaging list.
						</p>

						<h3>Sharing Your Information</h3>
						<p class="lead">
							We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except to trusted third parties who assist us in operating our website and conducting our business, so long as those parties agree to keep this information confidential.
						</p>

						<h3>Data Security</h3>
						<p class="lead">
							We implement a variety of security measures to maintain the safety of your personal information.
							While we strive to protect your personal information, no method of transmission over the Internet or method of electronic storage is 100% secure.
							Therefore, we cannot guarantee its absolute security.
						</p>

						<h3>Your Rights</h3>
						<p class="lead">
							You have the right to:
							<ul>
								<li>Access the personal information we hold about you</li>
								<li>Request corrections to any inaccuracies in your personal information</li>
								<li>Request deletion of your personal information, subject to certain exceptions</li>
							</ul>
							To exercise these rights, please contact us at info@lucentary.com.
						</p>

						<h3>Changes to This Privacy Policy</h3>
						<p class="lead">
							We may update this Privacy Policy from time to time.
							We will notify you of any changes by posting the new Privacy Policy on our website.
							Your continued use of our services after any modifications to the Privacy Policy will constitute your acknowledgment of the modifications and your consent to abide by and be bound by the modified policy.
						</p>

						<h3>Contact Us</h3>
						<p class="lead">
							If you have any questions about this Privacy Policy, please contact us at:
							<address>
								Lucentary Academy Inc<br/>
								Email: info@lucentary.com<br/>
								Website: https://lucentary.com
							</address>
						</p>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>
